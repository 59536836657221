import Image from "next/image";
import { FC } from "react";

const ScrollTopButton: FC = () => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };

    return <>
        <div className="container">
            <div className="border">
                <div className="button" onClick={() => scrollToTop()}>
                    <Image height={15} style={{transform:" rotate(270deg)"}} width={24} src="/assets/button-arrow.svg" alt="arrow" />
                </div>
            </div>
        </div>
        <style jsx>{
            `.container {
                position: fixed;
                bottom: 20px;
                right: 20px;
                height: 40px;
                display: inline-block;
                border-radius: 50%;
                background: linear-gradient(26.5deg, #054A97 14.11%, #9CCAFD 52.2%, #087BFB 68.1%, #0662C9 87.07%);
                z-index: 10000000;
            }
              
            .border {
                padding: 1px;
            }
              
            .button {
                font-weight: 700;
                background-color: black;
                color: white;
                display:flex;
                align-items: center;
                padding: 14px 8px;
                border-radius: 4px;
                height: 10px;
                cursor: pointer;
                text-decoration: none;
                text-transform: uppercase;
                border-radius: 50%
            }`
        }
        </style>
    </>
}

export default ScrollTopButton