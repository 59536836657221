import { FC, useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GTM: FC = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: "G-E2LKLV8XNC" });
  }, []);

  return null;
};

export default GTM;