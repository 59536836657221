import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState, useEffect, useRef } from 'react';
import styles from '../styles/Navbar.module.scss';
import ContactButton from './ContactButton';

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsSticky(scrollTop > 300);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = navbarRef.current?.offsetHeight || 0;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = sectionPosition - navbarHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const navigateToSection = (path: string, sectionId?: string) => {
    if (router.pathname !== path) {
      router.push(path).then(() => {
        if (sectionId) {
          scrollToSection(sectionId);
        }
      });
    } else if (sectionId) {
      scrollToSection(sectionId);
    }
  };

  const navigateToContact = () => {
    router.push('/?scrollTo=contact&source=products');
  };

  return (
    <nav className={`${styles.navbar} ${isSticky ? styles.sticky : ''}`} ref={navbarRef}>
      <div className={styles.navbarContainer}>
        <div className={styles.logo}>
          <div onClick={() => navigateToSection('/', 'home')}>
            <Image height={40} width={205} src="/assets/Logo.svg" alt="Neurobus" />
          </div>
        </div>
        <div className={styles.menu}>
          <div onClick={() => navigateToSection('/tech')}>
            Tech
          </div>
          <div onClick={() => navigateToSection('/products')}>
            Products
          </div>
          <div onClick={() => navigateToSection('/RnD')}>
            R&D Services
          </div>
          <div onClick={() => navigateToSection('/team')}>
            Team
          </div>
          <div onClick={() => navigateToSection('/news')}>
            News
          </div>
        </div>
        <a href="#contact" onClick={navigateToContact}>
          <ContactButton />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
