import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";

const Footer: FC = () => {
    const router = useRouter();
    const [navbarHeight, setNavbarHeight] = useState<number>(0);
    
    useEffect(() => {
        if(window.innerWidth < 1280) {
            setNavbarHeight(60);
        } else {
            setNavbarHeight(80);
        }
    }, [])

    const scrollToSection = (sectionId: string) => {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = sectionPosition - navbarHeight;
  
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    };

    const handleMenuItemClick = (sectionId: string) => {
        if(router.route != "/") {
          router.push("/").then(() => {
            const section = document.getElementById(sectionId);
    
            if (section) {
              const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
              const offsetPosition = sectionPosition - navbarHeight;
        
              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
              });
            }
          });
        } else {
          const section = document.getElementById(sectionId);
          if (section) {
            const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = sectionPosition - navbarHeight;
      
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        }    
      };

    return <>
      <div className="footer">
        <div className="footer__background"></div>
        <div className="footer__logo">
          <div className="contact-ellipse-4"></div>
          <Image width={205} height={40} src="/assets/Logo.svg" alt="Neurobus" />
        </div>
        <div>
          <div className="footer__info">
            <h2 className="footer__title home__section-title">Ready to get started?</h2>
            <span className="footer__text">
                Ready to explore the future of space technology with us?
                We&apos;re just one message away. Contact us and
                let&apos;s start the conversation.
            </span>
            <a href="#contact" onClick={() => scrollToSection('contact')}>
              <div className="footer__button">
                  Contact Us
              </div>
            </a>
          </div>
        </div>
        <div className="footer__links">
            <div className="footer__link" onClick={() => handleMenuItemClick("home")}>Home</div>
            <Link className="footer__link" href="https://www.linkedin.com/company/neurobus/">
              Linkedin 
              <img src={'assets/linkedin.svg'} alt="LinkedIn" style={{ width: '16px', marginLeft: '8px' }} />

            </Link>
            <Link className="footer__link" href="/terms-and-conditions">T&amp;C</Link>
            <Link className="footer__link" href="/privacy-policy">PRIVACY POLICY</Link>
        </div>
      </div>
    </>;
}

export default Footer;