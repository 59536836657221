import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/Navbar.module.scss'

const HamburgerMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = (sectionId: string) => {
    const targetRoute = routesMap[sectionId] || '/';
  
    if (sectionId === 'contact') {
      if (router.route !== '/') {
        router.push('/').then(() => {
          scrollToSection(sectionId);
        });
      } else {
        scrollToSection(sectionId);
      }
    } else {
      if (router.route !== targetRoute) {
        router.push(targetRoute);
      }
    }
  
    setMenuOpen(false);
  };
  
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    
    if (section) {
      const navbarHeight = menuRef.current?.offsetHeight || 0;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = sectionPosition - navbarHeight;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  
  const routesMap: { [key: string]: string } = {
    tech: '/tech',
    products: '/products',
    RnD: '/RnD',
    team: '/team',
    news: '/news',
    contact: '/',
  };
  

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  return (
    <div className={`${styles.hamburgerMenu} ${menuOpen ? 'open' : ''}`} ref={menuRef}>
        <div className={styles.mobileLogo}>
            <div onClick={() => router.push('/')}>
                <Image height={35} width={29} src="/assets/logo-mobile.svg" alt="Neurobus" />
            </div>
        </div>
        <label htmlFor={styles.menuToggle} className={styles.menuIcon}>
            <span className={`${styles.bar} ${menuOpen ? styles.barOpen : ''}`}></span>
        </label>
        <input
            type="checkbox"
            id={styles.menuToggle}
            checked={menuOpen}
            onChange={handleMenuToggle}
        />
        <div className={styles.mobileMenu}>
            <div className={styles.mobileMenuBkg}></div>
            <div onClick={() => handleMenuItemClick('tech')}>
              Tech
            </div>
            <div onClick={() => handleMenuItemClick('products')}>
              Products
            </div>
            <div onClick={() => handleMenuItemClick('RnD')}>
              R&D Services
            </div>
            <div onClick={() => handleMenuItemClick('team')}>
              Team
            </div>
            <div onClick={() => handleMenuItemClick('news')}>
              News
            </div>
            <div onClick={() => handleMenuItemClick('contact')}>
              Contact
            </div>
        </div>
    </div>
  );
};

export default HamburgerMenu;