import Footer from '@/components/Footer';
import GTM from '@/components/GTM';
import Navbar from '@/components/Navbar';
import NavbarMobile from '@/components/NavbarMobile';
import ScrollTopButton from '@/components/ScrollTopButton';
import '@/styles/globals.scss'
import type { AppProps } from 'next/app'
import React, { useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'

export default function App({ Component, pageProps }: AppProps) {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1280);
  };

  useEffect(() => {
    handleResize(); // Initial check

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleAccept = () => {
    // Handle accept action
  };

  const handleReject = () => {
    // Handle reject action
  };

  return <>
    {
      isMobile ? <>
          <NavbarMobile />
          <Component {...pageProps} />
          <ScrollTopButton />
          <Footer/>
        </> : <>
          <Navbar />
          <Component {...pageProps} />
          <Footer/>
        </>
    }
    <GTM />
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Reject"
      cookieName="website_cookie"
      style={{ background: "#000" }}
      buttonStyle={{ color: "#fff", background: "#000", border: "1px solid white", padding: "16px 48px" }}
      declineButtonStyle={{ color: "#fff", background: "#000", border: "1px solid white", padding: "16px 48px" }}
      onAccept={handleAccept}
      onDecline={handleReject}
    >
      <div className="cookie">
        <div className="cookie__title">Cookie Consent</div>
        <div className="cookie__description">
          This website uses cookies that help the website to function and also track 
          how you interact with our website. But for us to provide the best user experience, 
          enable the specific cookies from Settings and click on Accept.
        </div>
      </div>
      
    </CookieConsent>
  </>
}
