import styles from '../styles/ContactButton.module.scss';

const ContactButton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.border}>
        <div className={styles.button} >Contact</div>
      </div>
    </div>
  );
};

export default ContactButton;